.calculationtable1{
    table-layout: fixed;
    width: 100%;
    text-align: center;
    padding: 1%;
      
}

.calculationtable1.td, .calculationtable1.th{
    
    width: 20px;
    overflow: hidden;
} 

.calculationtable1 tbody tr:nth-child(even) {
    background-color: #e6f7ff; /* Set the background color for even rows */
  }
  
  .calculationtable1 tbody tr:nth-child(odd) {
    background-color: #ffffff; /* Set the background color for odd rows */
  }

.tableheaderinput{
    border: none;
    outline: none;
    width: 40%;
    color: rgb(19, 22, 25);
    background-color: rgb(177, 214, 246);
    border-radius: 10px;
    text-align: center;
    font-weight: 600;
}

.tablebodyinput{
    font-weight: 500;
    border: none;
    outline: none;
    width: 50%;
    text-align: center;
    
}
[id^='total_'] {
  font-weight: 600;
  font-size: 14px;
  color: #b93232;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}