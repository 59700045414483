.menu {
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 100px;
    align-items: center;
    background-color: #fff;
 
    width: 100%;
    position: absolute; 
    
}

.logo-menu {
    background-color: #fff;
    width: auto;
    
}


.menu a {
    font-size: 16px;
}

.navbarbtn{
    padding: 10px;
    background-color: #a84242;
    border-radius: 10px;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    
}
    
@media screen and (max-width: 600px) {
    
    .menu Button {
        position: absolute;
        right: 0;
        display: block;
        z-index: 1;
        margin-right:10px;
    

    }

    .menu a{
        visibility: hidden;
    }

    .menu Link .logo-menu {
        
        visibility: visible!important;
        margin-left: 100px;
        margin-top: 15px;
       min-width: 100%;
      
       
   
    }
    .menu {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
        margin-left: 0;
        margin-top: 0;
    }

    
}