.genotypelistcontainer{
    display: flex;
    flex-direction: column;

    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 2%;
    
}

.genotypelisthelp{
    position: absolute;
    top: 2%;
    left: 2%;

}

.genotypelistnavbar{
    margin-bottom: 6%;

}

.genotypelisttablecontainer{
    display: flex;
    align-self: center;
    
}

table, th, td {
    
    line-height: 16px;
    font-size: 14px;
    
    border: 2px solid #E8E7E7;
    border-collapse: collapse;
    padding-top: 0.5%;
    padding-bottom: 0.5%;

}

table {
    width: 100%;
}

td{
    width: 150px;
}
    
.genotypelisttable>thead{
    color: #0066FF;
       
}

.genotypelisttable>tbody{
    color: #606060;
    text-align: center;
}
