.registerplatecontainer {
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;

}
.genotypelisthelp{
    position: absolute;
    top: 2%;
    left: 2%;

}

.wrap {
    padding: 40px;
    text-align: center;
}

hr {
    clear: both;
    margin-top: 40px;
    margin-bottom: 40px;
    border: 0;
    border-top: 1px solid #aaaaaa;
}

h1 {
    font-size: 30px;
    margin-bottom: 40px;
}

p {
    margin-bottom: 20px;
}

.btn {
    background: #428bca;
    border: #357ebd solid 1px;
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    padding: 8px 15px;
    text-decoration: none;
    text-align: center;
    min-width: 60px;
    position: relative;
    transition: color .1s ease;
}

.btn:hover {
    background: #357ebd;
}

.btn.btn-big {
    font-size: 18px;
    padding: 15px 20px;
    min-width: 100px;
}

.btn-close {
    color: #aaaaaa;
    font-size: 30px;
    text-decoration: none;
    position: absolute;
    right: 5px;
    top: 0;
}

.btn-close:hover {
    color: #919191;
}

.modal:target:before {
    display: none;
}

.modal:before {
    content: "";
    display: block;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

.modal .modal-dialog {
    background: #fefefe;
    border: #333333 solid 1px;
    border-radius: 5px;
    margin-left: -200px;
    position: fixed;
    left: 50%;
    z-index: 11;
    width: 360px;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    top: 20%;
}

.modal:target .modal-dialog {
    top: -100%;
    -webkit-transform: translate(0, -500%);
    -ms-transform: translate(0, -500%);
    transform: translate(0, -500%);
}

.modal-body {
    padding: 20px;
}

.modal-header,
.modal-footer {
    padding: 10px 20px;
}

.modal-header {
    border-bottom: #eeeeee solid 1px;
}

.modal-header h2 {
    font-size: 20px;
}

.modal-footer {
    border-top: #eeeeee solid 1px;
    text-align: right;
}

.inputsubcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0% 0% 1% 0% ;
}

.selectplatecomponent {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    width: 40%;
   
}

.plateselectiondropdowntitle {
    margin: 5% 0% 5% 0%;
    line-height: 20px;
    font-size: 18px !important;
    font-weight: 600;
    color: #447ccf;
}

.plateselectiondropdownmenu {
    padding: 2% 0% 2% 3%;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    color: #666666;
    background-color: #e0ebf4;
    border-radius: 10px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat, repeat;
    background-position: right 5% top 50%, 0 0;
    background-size: 0.65em auto, 100%;
}

.plateselectiondropdownmenu:hover {
    border: none;
}

.plateselectiondropdownmenu:after {
    margin-top: 5%;
}

.alterplatecellsubsubcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
padding:10px;
    width: 450px;
    color: #666666;
    background-color: #e0ebf4;
    border-radius: 10px;
}

.alterplatecellinput {
    display: flex;
    flex-direction: column;
}

.cellinputtext {
    height: 20px;
    width: 300px;
    margin-top: 2%;
    padding: 5px;
    border-radius: 3px;
    border: none;
    outline: none;
    color: #666666;
}

.celltextlabel {
    
    margin-top: 3%;
    font-size: 14px;
    font-weight: 600;
    border: none;
    outline: none;
    color: #1E74F6;
}

.registerplatebtn{
    margin-top: 10px;
    
    padding: 3%;
    background-color: #a84242;
    border-radius: 10px;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    
}

.registerplatetablesubcontainer,
.labcalculationtablesubcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.registerplatetable {
    text-align: center;
}

.registerplatetable>tbody>tr>td {
    height: 60px;
    padding-left: 0.5%;
    padding-right: 0.5%;

}

.registerplatetable>tbody>tr:nth-child(odd) {
    background-color: #94BDFB;
}

.buttonsubcontainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    margin-top: 2%;
    margin-bottom: 2%;
    margin-right: 5%;
}

.registerplatebutton {
    margin: 1%;
    padding: 1%;
    background-color: #9DCAFF;
    color: white;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}



.printplatebutton {
    margin: 1%;
    padding: 1%;
    background-color: #759ECF;
    color: white;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

.submitplatebutton {
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: 1%;
    padding: 1%;
    background-color: #415A78;
    color: white;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}


.registerplatebutton:hover,
.printplatebutton:hover,
.submitplatebutton:hover {
    background-color: #A84242;
    color: white;
}

.helpmodal {
    top: 20px;
}