.logincontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;  
}

.loginsubcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0,0,0,0.5);
    padding: 50px 60px 50px 60px;
    width: 350px;
    margin: 20% 0% 30% 0%;
    
}

.logosubcontainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10%;
}

.logoelement{
    height: 4em;
    width: auto;
}

.formsubcontainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
