.registergenotypecontainer{
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
}
.genotypelisthelp{
    position: absolute;
    top: 2%;
    left: 2%;

}
.registergenotypenavbar{
    margin-left: 30%;
    margin-right: 50%;
    
}


.registergenotypesubcontainer1{
    margin-top: 5%;
    display: flex;
    flex-direction: column;

  
    flex-direction: row;
    justify-content: space-between;
}


.plateselectiondropdowntitle {
   margin: 5% 0% 5% 0%;
    line-height: 20px;
    font-size: 18px !important;
    font-weight: 600;
    color: #447ccf;
}

.plateselectiondropdownmenu {
    padding: 15px 40px 15px 20px;
    margin: 20px;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    color: #666666;
    background-color: #e0ebf4;
    border-radius: 10px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    background-position: right 5% top 50%, 0 0;
    background-size: 0.65em auto, 100%;
}

.plateselectiondropdownmenu:hover {
    border: none;
}

.plateselectiondropdownmenu:after {
    margin-top: 5%;
}



.registergenotypesubcontainer2{
    display: flex;
    align-items: center;
    justify-content: center;
}

.registergenotypetable{
    text-align: center;
    
}


.even-row {
    background-color: #94bdfb; /* Light blue */
  }
  
  .odd-row {
    background-color: #ffffff; /* White */
  }
  


th{
    color: #606060
}

.registergenotypetable>tbody>tr>td{
    height: 40px;
        
}

.registergenotypetable>tbody>tr>td{
    padding-left: 2%;
        
}


.registerplatebutton{
    margin: 1%;
    padding: 1%;
    background-color: #9DCAFF;
    color: white;
    border-radius: 10px;
    border: none;
}

.submitplatebutton{
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: 1%;
    padding: 1%;
    background-color: #415A78;
    color: white;
    border-radius: 10px;
    border: none;
}
.submitplatebutton:disabled {
    background-color: #8c8c8c; 
    color: #ffffff; 
    cursor: not-allowed; 
  }
  
.buttonsubcontainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
    margin-top: 2%;
    margin-bottom: 2%;
    margin-right: 5%;
}
.popup-content {
    margin: auto;
    margin-bottom: 2%;
    background: #6979F8;
    width: auto;
    padding: 0.5%;
    border-radius: 5px;
  
}

.popup-arrow {
    display: none;
}

.popup-content:hover{
    background-color: coral;
}





